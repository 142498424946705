<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedValue"
        v-bind="attrs"
        v-on="on"
        style="max-width: 290px;"
        readonly
        :disabled="disabled"
        :clearable="clearable"
        :label="$t('global.month')"
      />
    </template>
    <v-date-picker v-model="innerValue" type="month" color="primary" @input="menu = false" />
  </v-menu>
</template>

<script>
  export default {
    name: "MonthPicker",
    props: {
      value: String,
      clearable: Boolean,
      disabled: Boolean
    },
    data: () => ({
      menu: false
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      formattedValue: {
        get() {
          return this.innerValue ? this.$moment(this.innerValue).format("MMMM") : null;
        },
        set() {
          this.innerValue = null;
        }
      }
    }
  };
</script>
